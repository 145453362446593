import * as React from "react";

import Topic from "../components/topic";

// markup
const ServicePage = () => {
  const post = {
    title: 'Hire Dedicated Web App Developers | Hire Web App Developers',
    metaDescription: 'Hire Top Dedicated ReactJS Developers & programmers in India to build interactive and instinctive software and React.js application for your enterprise and startup.',
    h1: 'Hire Dedicated Web App Developers',
    h1Subtext1: 'Our creativity starts with Design, Development, and Marketing to create better designs.',
    h1Subtext2: 'MatteCurve - The best Web App development company in India. We are a JavaScript company and have dedicated Web App developers for you.',
    aboutSection: {
        title: 'About Web App',
        subTitle: 'Build Scalable, Responsive, and Secure Web Applications',
        paragraph1: "Web Application Development plays a huge role in generating employment. It is one of the fastest growing careers in the tech industry who is involved in the development and design of web and/or network applications.",
        paragraph2: "Web application development is an interactive computer program built with web technologies that are capable of creating attractive websites which reside on remote servers and are delivered to the user’s device over the Internet. It allows users to add inputs and get data in many ways via interactions.",
        keyPoints: [
            "Web App uses the JSX template, which is a simple template that can replace regular JavaScript. It allows HTML quoting, using the HTML tag syntax to render sub components.",
            "Web App creates an in-memory data structure cache that computes the changes made and then updates the browser.",
            "This enables the developer to code as if the whole page will render on each change, whereas the React library only renders components that actually change.",
            "Web App also allows the developer to create components in the code, which can be reused whenever needed.",
            "Web App components can be understood and rendered easily without having to trace the program flow.",
            "Web App can be rendered on the server directly thereby making the development process faster and cost-effective for SEO."
        ],
    },
    statsSection: {
        paragraph: "In today's growing market web applications development  plays a huge role in generating employment. Websites offer credibility to your business and support online promotion of products.  Web App Development is gaining a huge market day by day due to its user-friendly environment. Hire Dedicated Web App Developers in India from MatteCurve to grow by 4X.",
    },
    benefitsSection: {
        title: "Benefits of Hiring Dedicated Web App Developers",
        keyPoints: [
            "Improve Project Outcomes",
            "Cost-effective",
            "No investment On Hardware/Software Requirement",
            "Get Access to Skilled Developers",
            "Agile Approach",
            "Collaborative Engagement",
            "Painless Project Management",
            "Greater focus on business"
        ],
        image: "https://images.unsplash.com/photo-1603404235009-71c1f80e31fc?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1000&q=80",
    },
    ctaTexts: [
        "Hire dedicated Web App Developers?",
        "Hire dedicated Web App Developers from MatteCurve?",
    ],
    why: {
        title: "Why Choose MatteCurve for Web App Development?",
        paragraph: "MatteCurve has been recognized as one of the leading web development companies in building high-performance Web App apps from India. MatteCurve offers you deep expertise in Web App web development and Web App app development, delivered by a team of highly-skilled Web App Developers with unmatched expertise in building progressive and dynamic web apps using Web App.",
    }
  };


  return (
    <Topic post={post}></Topic>
  )
}

export default ServicePage;
